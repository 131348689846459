import { useEffect } from 'react';
import './demo.scss'

const HomeDemo = (): JSX.Element => {
  useEffect(() => {
    // https://0xwe.org/
    // 印尼：https://hascaring.org/pay
    // 马来：https://hascharity.org/pay
    // window.open('https://hascharity.org/pay', '_self')
    // window.open('https://play.google.com/store/apps/details?id=com.mojang.minecraftpe&hl=zh&gl=US', '_self')
    window.open('https://play.google.com/store/apps/details?id=com.crunchyroll.opm&hl=en-us&gl=us', '_self')

  })
  return (
    <>

    </>
  );
}

export default HomeDemo;